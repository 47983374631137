import "./App.css";
import {
  BrowserRouter as Router,
  Route,
  Redirect,
  Switch
} from "react-router-dom";
import Header from "./Components/Header/Header.js";
import About from "./Components/About/About.js";
import Footer from "./Components/Footer/Footer.js";
import FrontText from "./Components/FrontText/FrontText.js";
import Collection from "./Components/Gallery/Collection";
import Contact from "./Components/ContactUs/Contact.js";
import Services from "./Components/Services/ServicePasser.js";
import Slider from "./Components/PhotoSlider/Slider";
import ServicePasser from "./Components/Services/ServicePasser.js";
import InsideService from './Components/Services/Services'
function App() {
  return (
    <div>
      <Header />
      <Router>
        <Switch>
          <Route exact  path="/" >
            <Slider />
            <FrontText />
            <ServicePasser />
            <Contact />
          </Route >
           <Route path="/home">
            <Slider />
            <FrontText />
            <ServicePasser />
            <Contact />
          </Route>
          <Route path="/about">
            <About />
          </Route>
          <Route path="/gallery">
            <Collection />
          </Route>
          <Route path="/services">
            <InsideService/>
          </Route>
          <Route path="/contact">
            <Contact />
          </Route>
        </Switch>
      </Router>
      <Footer />
    </div>
  );
}

export default App;
